.adm-calendar-picker .adm-calendar-picker-title,
.adm-calendar-picker .adm-calendar-picker-view-title,
.adm-calendar-picker .adm-calendar-picker-popup-title,
.adm-calendar-picker-view .adm-calendar-picker-title,
.adm-calendar-picker-view .adm-calendar-picker-view-title,
.adm-calendar-picker-view .adm-calendar-picker-popup-title,
.adm-calendar-picker-popup .adm-calendar-picker-title,
.adm-calendar-picker-popup .adm-calendar-picker-view-title,
.adm-calendar-picker-popup .adm-calendar-picker-popup-title {
  flex: auto;
  font-size: var(--adm-font-size-10);
}
.adm-calendar-picker .adm-calendar-picker-header,
.adm-calendar-picker .adm-calendar-picker-view-header,
.adm-calendar-picker .adm-calendar-picker-popup-header,
.adm-calendar-picker-view .adm-calendar-picker-header,
.adm-calendar-picker-view .adm-calendar-picker-view-header,
.adm-calendar-picker-view .adm-calendar-picker-popup-header,
.adm-calendar-picker-popup .adm-calendar-picker-header,
.adm-calendar-picker-popup .adm-calendar-picker-view-header,
.adm-calendar-picker-popup .adm-calendar-picker-popup-header {
  padding: 12px;
  border-bottom: 1px solid var(--adm-color-border);
}
.adm-calendar-picker .adm-calendar-picker-header .adm-calendar-picker-view-title,
.adm-calendar-picker .adm-calendar-picker-view-header .adm-calendar-picker-view-title,
.adm-calendar-picker .adm-calendar-picker-popup-header .adm-calendar-picker-view-title,
.adm-calendar-picker-view .adm-calendar-picker-header .adm-calendar-picker-view-title,
.adm-calendar-picker-view .adm-calendar-picker-view-header .adm-calendar-picker-view-title,
.adm-calendar-picker-view .adm-calendar-picker-popup-header .adm-calendar-picker-view-title,
.adm-calendar-picker-popup .adm-calendar-picker-header .adm-calendar-picker-view-title,
.adm-calendar-picker-popup .adm-calendar-picker-view-header .adm-calendar-picker-view-title,
.adm-calendar-picker-popup .adm-calendar-picker-popup-header .adm-calendar-picker-view-title {
  text-align: center;
}
.adm-calendar-picker .adm-calendar-picker-body,
.adm-calendar-picker .adm-calendar-picker-view-body,
.adm-calendar-picker .adm-calendar-picker-popup-body,
.adm-calendar-picker-view .adm-calendar-picker-body,
.adm-calendar-picker-view .adm-calendar-picker-view-body,
.adm-calendar-picker-view .adm-calendar-picker-popup-body,
.adm-calendar-picker-popup .adm-calendar-picker-body,
.adm-calendar-picker-popup .adm-calendar-picker-view-body,
.adm-calendar-picker-popup .adm-calendar-picker-popup-body {
  height: 64vh;
  overflow: auto;
}
.adm-calendar-picker .adm-calendar-picker-body::-webkit-scrollbar,
.adm-calendar-picker .adm-calendar-picker-view-body::-webkit-scrollbar,
.adm-calendar-picker .adm-calendar-picker-popup-body::-webkit-scrollbar,
.adm-calendar-picker-view .adm-calendar-picker-body::-webkit-scrollbar,
.adm-calendar-picker-view .adm-calendar-picker-view-body::-webkit-scrollbar,
.adm-calendar-picker-view .adm-calendar-picker-popup-body::-webkit-scrollbar,
.adm-calendar-picker-popup .adm-calendar-picker-body::-webkit-scrollbar,
.adm-calendar-picker-popup .adm-calendar-picker-view-body::-webkit-scrollbar,
.adm-calendar-picker-popup .adm-calendar-picker-popup-body::-webkit-scrollbar {
  display: none;
}
.adm-calendar-picker .adm-calendar-picker-body .adm-calendar-picker-view-title,
.adm-calendar-picker .adm-calendar-picker-view-body .adm-calendar-picker-view-title,
.adm-calendar-picker .adm-calendar-picker-popup-body .adm-calendar-picker-view-title,
.adm-calendar-picker-view .adm-calendar-picker-body .adm-calendar-picker-view-title,
.adm-calendar-picker-view .adm-calendar-picker-view-body .adm-calendar-picker-view-title,
.adm-calendar-picker-view .adm-calendar-picker-popup-body .adm-calendar-picker-view-title,
.adm-calendar-picker-popup .adm-calendar-picker-body .adm-calendar-picker-view-title,
.adm-calendar-picker-popup .adm-calendar-picker-view-body .adm-calendar-picker-view-title,
.adm-calendar-picker-popup .adm-calendar-picker-popup-body .adm-calendar-picker-view-title {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  padding: 8px 20px;
  background-color: var(--adm-color-box);
}
.adm-calendar-picker .adm-calendar-picker-footer-bottom,
.adm-calendar-picker .adm-calendar-picker-view-footer-bottom,
.adm-calendar-picker .adm-calendar-picker-popup-footer-bottom,
.adm-calendar-picker-view .adm-calendar-picker-footer-bottom,
.adm-calendar-picker-view .adm-calendar-picker-view-footer-bottom,
.adm-calendar-picker-view .adm-calendar-picker-popup-footer-bottom,
.adm-calendar-picker-popup .adm-calendar-picker-footer-bottom,
.adm-calendar-picker-popup .adm-calendar-picker-view-footer-bottom,
.adm-calendar-picker-popup .adm-calendar-picker-popup-footer-bottom {
  padding: 0 20px 16px;
}
.adm-calendar-picker .adm-calendar-picker-footer .adm-divider,
.adm-calendar-picker .adm-calendar-picker-view-footer .adm-divider,
.adm-calendar-picker .adm-calendar-picker-popup-footer .adm-divider,
.adm-calendar-picker-view .adm-calendar-picker-footer .adm-divider,
.adm-calendar-picker-view .adm-calendar-picker-view-footer .adm-divider,
.adm-calendar-picker-view .adm-calendar-picker-popup-footer .adm-divider,
.adm-calendar-picker-popup .adm-calendar-picker-footer .adm-divider,
.adm-calendar-picker-popup .adm-calendar-picker-view-footer .adm-divider,
.adm-calendar-picker-popup .adm-calendar-picker-popup-footer .adm-divider {
  margin-top: 0;
}
.adm-calendar-picker .adm-calendar-picker-footer .adm-button,
.adm-calendar-picker .adm-calendar-picker-view-footer .adm-button,
.adm-calendar-picker .adm-calendar-picker-popup-footer .adm-button,
.adm-calendar-picker-view .adm-calendar-picker-footer .adm-button,
.adm-calendar-picker-view .adm-calendar-picker-view-footer .adm-button,
.adm-calendar-picker-view .adm-calendar-picker-popup-footer .adm-button,
.adm-calendar-picker-popup .adm-calendar-picker-footer .adm-button,
.adm-calendar-picker-popup .adm-calendar-picker-view-footer .adm-button,
.adm-calendar-picker-popup .adm-calendar-picker-popup-footer .adm-button {
  width: 100%;
}
.adm-calendar-picker-cells,
.adm-calendar-picker-view-cells,
.adm-calendar-picker-popup-cells {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  padding: 4px 8px;
}
.adm-calendar-picker-cell,
.adm-calendar-picker-view-cell,
.adm-calendar-picker-popup-cell {
  flex: none;
  box-sizing: border-box;
  width: calc(100% / 7);
  min-height: 55px;
  margin-bottom: 4px;
  padding: 2px;
  color: var(--adm-color-text);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-today,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-today,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-today,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-today,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-today,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-today,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-today,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-today,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-today {
  color: var(--adm-color-primary);
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled {
  color: var(--adm-color-light);
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled .adm-calendar-picker-view-cell-bottom {
  color: var(--adm-color-light);
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected {
  background: rgba(22, 119, 255, 0.1);
  color: var(--adm-color-text);
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected .adm-calendar-picker-view-cell-bottom {
  color: var(--adm-color-white);
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-begin,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-begin {
  background: var(--adm-color-primary);
  color: var(--adm-color-white);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected-end,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected-end {
  background: var(--adm-color-primary);
  color: var(--adm-color-white);
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-view-cell.adm-calendar-picker-popup-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-view-cell-selected,
.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-disabled.adm-calendar-picker-popup-cell.adm-calendar-picker-popup-cell-selected {
  color: var(--adm-color-light);
}
.adm-calendar-picker-cell .adm-calendar-picker-cell-date,
.adm-calendar-picker-cell .adm-calendar-picker-view-cell-date,
.adm-calendar-picker-cell .adm-calendar-picker-popup-cell-date,
.adm-calendar-picker-view-cell .adm-calendar-picker-cell-date,
.adm-calendar-picker-view-cell .adm-calendar-picker-view-cell-date,
.adm-calendar-picker-view-cell .adm-calendar-picker-popup-cell-date,
.adm-calendar-picker-popup-cell .adm-calendar-picker-cell-date,
.adm-calendar-picker-popup-cell .adm-calendar-picker-view-cell-date,
.adm-calendar-picker-popup-cell .adm-calendar-picker-popup-cell-date {
  flex: none;
  line-height: 22px;
  font-size: var(--adm-font-size-8);
}
.adm-calendar-picker-cell .adm-calendar-picker-cell-top,
.adm-calendar-picker-cell .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-cell .adm-calendar-picker-popup-cell-top,
.adm-calendar-picker-view-cell .adm-calendar-picker-cell-top,
.adm-calendar-picker-view-cell .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-view-cell .adm-calendar-picker-popup-cell-top,
.adm-calendar-picker-popup-cell .adm-calendar-picker-cell-top,
.adm-calendar-picker-popup-cell .adm-calendar-picker-view-cell-top,
.adm-calendar-picker-popup-cell .adm-calendar-picker-popup-cell-top,
.adm-calendar-picker-cell .adm-calendar-picker-cell-bottom,
.adm-calendar-picker-cell .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-cell .adm-calendar-picker-popup-cell-bottom,
.adm-calendar-picker-view-cell .adm-calendar-picker-cell-bottom,
.adm-calendar-picker-view-cell .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-view-cell .adm-calendar-picker-popup-cell-bottom,
.adm-calendar-picker-popup-cell .adm-calendar-picker-cell-bottom,
.adm-calendar-picker-popup-cell .adm-calendar-picker-view-cell-bottom,
.adm-calendar-picker-popup-cell .adm-calendar-picker-popup-cell-bottom {
  flex: none;
  font-size: var(--adm-font-size-1);
  height: 14px;
  line-height: 14px;
  color: var(--adm-color-weak);
}
.adm-calendar-picker-mark,
.adm-calendar-picker-view-mark,
.adm-calendar-picker-popup-mark {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: solid 1px var(--adm-color-border);
  height: 45px;
  box-sizing: border-box;
  font-size: var(--adm-font-size-6);
  padding: 0 8px;
}
.adm-calendar-picker-mark .adm-calendar-picker-mark-cell,
.adm-calendar-picker-mark .adm-calendar-picker-view-mark-cell,
.adm-calendar-picker-mark .adm-calendar-picker-popup-mark-cell,
.adm-calendar-picker-view-mark .adm-calendar-picker-mark-cell,
.adm-calendar-picker-view-mark .adm-calendar-picker-view-mark-cell,
.adm-calendar-picker-view-mark .adm-calendar-picker-popup-mark-cell,
.adm-calendar-picker-popup-mark .adm-calendar-picker-mark-cell,
.adm-calendar-picker-popup-mark .adm-calendar-picker-view-mark-cell,
.adm-calendar-picker-popup-mark .adm-calendar-picker-popup-mark-cell {
  flex: 1;
  text-align: center;
}
